// src/pages/PricingPage.tsx

import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import emailjs from "@emailjs/browser";

const pricingOptions = [
  { label: "Inventory management", explanation: "Track, edit, and manage your inventory", value: 10000 },
  {
    label: "Accounting integration",
    explanation: "Invoicing, estimating, and customer management",
    value: 10000,
  },
  {
    label: "Shipping, manufacturing, and ordering",
    explanation: "Link your inventory and accounting to shipping, manufacturing, and ordering",
    value: 10000,
  },
  {
    label: "Update public-facing website",
    explanation: "Add estimates, payments, invoices, etc.",
    value: 10000,
  },
  {
    label: "Rental Management",
    explanation: "Rent out items from your inventory. Document the condition, track schedules, and send reminders",
    value: 10000,
  },
  {
    label: "Forecasting and analytics",
    explanation:
      "Predict sales and inventory changes and chart historical trends. (Requires accounting integration.)",
    value: 5000,
  },
  {
    label: "AI-generated, data-driven messaging",
    explanation:
      "Use machine learning and your past sales and outreach data to understand when, how, and what to include in your messaging to convert current and future customers.",
    value: 30000,
  },
  {
    label: "10 hours of additional updates and/or training",
    explanation: "Update your current software or add training time",
    value: 2000,
  },
  {
    label: "Remote setup",
    explanation: "10 days, 4 hours per day, includes training",
    value: 5000,
  },
  {
    label: "On-site setup",
    explanation: "5 days, 8 hours per day, includes training",
    value: 10000,
  }
];

const defaultSelectedOptions = {
  "Inventory management": true,
  "Accounting integration - customer tracking, estimating and invoicing": false,
  "Task management - shipping, ordering, and manufacturing": false,
  "Rental Management": false,
 "Forecasting and analytics": false,
  "10 hours of additional updates and/or training": false,
  "Add Estimates to public-facing website": false,
  "Remote setup": false,
  "On-site setup": false,
  "Sales and marketing predictions": false,
};

const PricingPage: React.FC = () => {
  const [selectedOptions, setSelectedOptions] = useState<{
    [key: string]: boolean;
  }>(defaultSelectedOptions);
  const [getEstimate, setGetEstimate] = useState(false);
  const [billingToDisplay, setBillingToDisplay] = useState('24');


  const handleDivClick = (label: string) => {
    setSelectedOptions((prevState) => {
      const newState = { ...prevState, [label]: !prevState[label] };

      if (label === "Remote setup" && newState[label]) {
        newState["On-site setup"] = false;
      } else if (label === "On-site setup" && newState[label]) {
        newState["Remote setup"] = false;
      }

      return newState;
    });
  };

  useEffect(() => {
    emailjs.init("uzd_-uTNyGMC9EakZ");
  }, []);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const serviceID = "service_avvjmbh";
    const templateID = "template_odtkctq";
    emailjs
      .sendForm(serviceID, templateID, e.currentTarget)
      .then((response) => {
        console.log("Email successfully sent!", response.status, response.text);
      })
      .catch((error) => {
        console.error("Failed to send email. Error:", error);
      });
    e.currentTarget.reset();
  };

  const calculatePrice = () => {
    const totalPrice = pricingOptions.reduce((total, option) => {
      return total + (selectedOptions[option.label] ? option.value : 0);
    }, 0);
    return totalPrice;
  };

  const formatUSD = (value: number) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(value);
  };
//bg-[#EBF0F6]
  return (
    <div className="min-h-screen text-center p-2 md:p-6">
                  <Helmet>
      <title>Optima Inventory - Pricing</title>
      <meta charSet="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <meta name="description" content="Optima Inventory is the easy, integrated solution for small business inventory tracking, forecasting, and integration." />
    <title>Optima Inventory</title>


    <link rel="icon" href="/favicon.ico" />
    <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
    <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
    <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
    <link rel="manifest" href="/manifest.json" />

 
    <meta property="og:title" content="Optima Inventory - Pricing" />
    <meta property="og:description" content="Optima Inventory is the easy, integrated solution for small business inventory tracking, forecasting, and integration." />
    <meta property="og:type" content="website" />
    <meta property="og:url" content="https://www.optima-inventory.com" />
    <meta property="og:image" content="https://www.optima-inventory.com/logo.png" />

 
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content="Optima Inventory - Pricing" />
    <meta name="twitter:description" content="Optima Inventory is the easy, integrated solution for small business inventory tracking, forecasting, and integration." />
    <meta name="twitter:image" content="https://www.optima-inventory.com/logo.png" />
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=G-6BE1H8NTZ2`}
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-6BE1H8NTZ2');
          `}
        </script>
      </Helmet>
      <div className="flex justify-center items-center space-x-2">
        <div className="h-6 w-6 rounded-sm overflow-hidden space-y-px mb-10 mt-px">
          <div className={`${
                selectedOptions["Accounting integration"]
                  && "bg-emerald-500"} h-1 w-8`}></div>
          <div className={`${
                selectedOptions["Shipping, manufacturing, and ordering"]
                  && "bg-teal-500"} h-1 w-8`}></div>
          <div className={`${
                selectedOptions["Update public-facing website"]
                  && "bg-sky-500"} h-1 w-8`}></div>
          <div className={`${
                selectedOptions["Rental Management"]
                  && "bg-violet-500"} h-1 w-8`}></div>
          <div className={`${
            (selectedOptions["Forecasting and analytics"])
                
                  && "bg-pink-500"} h-1 w-8`}></div>
        </div>
        <h1 className="text-4xl font-bold text-center mb-10">
          Pricing
        </h1>
      </div>
      <div className="mb-2 text-slate-900 text-left text-lg">
          Options:
        </div>
      <div className="px-2">


      

        <div className="grid md:grid-cols-2 gap-4">
          {pricingOptions.map((option) => (
            <div
              key={option.label}
              className={`p-2 rounded-lg cursor-pointer 
              
                ${
                selectedOptions[option.label]
                  ? "bg-black text-slate-50"
                  : "bg-slate-200 bg-opacity-50"
              }`}
              onClick={() => handleDivClick(option.label)}
            >
              <p className="text-lg">{option.label}</p>
              <p className={`${selectedOptions[option.label]
                  ? "text-slate-400" : "text-slate-800"} text-sm`}>{option.explanation}</p>
              <p className={`${selectedOptions[option.label]
                  ? "text-slate-300" : "text-slate-600"}`}>
                    {formatUSD(option.value)}
                    </p>
            </div>
          ))}
        </div>
      </div>
      <div className="mb-2 text-slate-900 text-left text-lg">
          Schedule:
        </div>
      <div className="grid grid-cols-3 text-slate-900 text-center gap-1 px-2">
      <div className={`rounded-lg p-1 cursor-pointer ${billingToDisplay === "24" ? "bg-black text-slate-50":"bg-slate-200 bg-opacity-50"}`} onClick = {()=>setBillingToDisplay('24')}>Over 24 months - {formatUSD(Math.ceil(calculatePrice()/24/10)*10)}/month</div>
      <div className={`rounded-lg p-1 cursor-pointer ${billingToDisplay === "12" ? "bg-black text-slate-50":"bg-slate-200 bg-opacity-50"}`} onClick = {()=>setBillingToDisplay('12')}>Over 12 months - {formatUSD(Math.ceil(calculatePrice()/12/10)*10)}/month</div>

      <div className={`rounded-lg p-1 cursor-pointer ${billingToDisplay === "one" ? "bg-black text-slate-50":"bg-slate-200 bg-opacity-50"}`} onClick = {()=>setBillingToDisplay('one')}>One-time - {formatUSD(calculatePrice())}</div>
    </div>
      <div className="mx-2">
        {!getEstimate && (
          <button
            className="rounded-lg text-center text-white py-2 w-full mt-4 bg-black"
            onClick={() => setGetEstimate(true)}
          >
            Get an Estimate
          </button>
        )}
        {getEstimate && (
          <div>
        <div className="mb-2 text-sm text-slate-600 text-center mt-4">
          Add your name and email, and we'll get the process started.
        </div>
            <div className="flex justify-center text-center">
              <form
                id="contact-form"
                onSubmit={handleSubmit}
                className="grid md:grid-cols-2 gap-4 w-full"
              >
               
            
                  <input type="text" className="placeholder-slate-500 text-center rounded-md py-1 focus:ring-0 outline-0" placeholder="Name" required />
              
            
               
                  <input type="email" className="placeholder-slate-500 text-center rounded-md py-1 focus:ring-0 outline-0" placeholder="Email" required />
      
               
        
              </form>
            </div>
            <button
              type="submit"
              className="rounded-lg text-center text-white py-2 w-full mt-4 bg-black"
            >
              Send Request
            </button>
          </div>
        )}
        <div className="mb-2 text-slate-900 text-sm text-center mt-10">
          <p>To get the process started, send your estimate, and we'll contact you to set up a meeting.</p>
          <p> In the initial meeting, we'll figure out exactly what you need and establish a timeline and set a project price.</p>
        </div>

         <section className="mb-32 mt-10">
        <h2 className="text-3xl font-semibold text-center mb-4">What's Included</h2>
        <div className="flex justify-center">
        <ul className="space-y-2 list-disc list-inside text-left">
        
          <li>Full rights, no subscriptions. The software is yours as an asset. The only monthly fee you'll pay is the hosting fee (around $20.00), and that's paid directly to Amazon Web Services. </li>
          <li>Ability to spread your payments out over 12 or 24 months.</li>
          <li>Ability for multiple locations. If you have more than one location, it's still one setup fee.</li>
          <li>The software is exclusively React and Node.js. This means that any React developer can understand and edit the software.</li>
          <li>Full customization. When setting everything up, we'll assess your company's needs and add, update, and delete features accordingly.</li>
        </ul>
        </div>
      </section>
      </div>
    </div>
  );
};

export default PricingPage;


/* with monthly pricing
// src/pages/PricingPage.tsx

import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import PricingOne from '../components/pricingOne'
import PricingMonthly from '../components/pricingMonthly'


const PricingPage: React.FC = () => {


  const [tabToDisplay, setTabToDisplay] = useState('monthly');



  return (
    <div className="min-h-screen text-center p-2 md:p-6">
      <Helmet>
        <title>Optima Inventory - Pricing</title>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta
          name="description"
          content="Optima Inventory is the easy, integrated solution for small business inventory tracking, forecasting, and integration."
        />
        <title>Optima Inventory</title>

        <link rel="icon" href="/favicon.ico" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/manifest.json" />

        <meta property="og:title" content="Optima Inventory - Pricing" />
        <meta
          property="og:description"
          content="Optima Inventory is the easy, integrated solution for small business inventory tracking, forecasting, and integration."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.optima-inventory.com" />
        <meta
          property="og:image"
          content="https://www.optima-inventory.com/logo.png"
        />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Optima Inventory - Pricing" />
        <meta
          name="twitter:description"
          content="Optima Inventory is the easy, integrated solution for small business inventory tracking, forecasting, and integration."
        />
        <meta
          name="twitter:image"
          content="https://www.optima-inventory.com/logo.png"
        />
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=G-6BE1H8NTZ2`}
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-6BE1H8NTZ2');
          `}
        </script>
      </Helmet>
      <div className="flex justify-center items-center space-x-2">

  
        <h1 className="text-4xl font-bold text-center mb-10">Pricing</h1>
      </div>
      <section className="">
    <div className="grid grid-cols-2 text-slate-900 text-center gap-1">
      <div className={`rounded-lg p-1 cursor-pointer ${tabToDisplay === "monthly" ? "bg-white border border-slate-200 ":"bg-slate-200"}`} onClick = {()=>setTabToDisplay('monthly')}>Monthly</div>

      <div className={`rounded-lg p-1 cursor-pointer ${tabToDisplay === "one" ? "bg-white border border-slate-200 ":"bg-slate-200"}`} onClick = {()=>setTabToDisplay('one')}>One-time</div>
    </div>
    {tabToDisplay === 'monthly' && (

      <PricingMonthly />
      
    )}
    {tabToDisplay === 'one' && (
      <PricingOne />
    )}
    

    </section>
    </div>
  );
};

export default PricingPage;
*/